import React from "react";

export default ({className = "icon"}) => (
  <svg
    t="1599626869798"
    className="icon"
    viewBox="0 0 1097 1024"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    p-id="2558"
    className={className}
  >
    <path
      d="M352.571429 799.428571l-28.571429 28.571429q-5.714286 5.714286-13.142857 5.714286t-13.142857-5.714286L31.428571 561.714286q-5.714286-5.714286-5.714285-13.142857t5.714285-13.142858l266.285715-266.285714q5.714286-5.714286 13.142857-5.714286t13.142857 5.714286l28.571429 28.571429q5.714286 5.714286 5.714285 13.142857t-5.714285 13.142857L128 548.571429l224.571429 224.571428q5.714286 5.714286 5.714285 13.142857t-5.714285 13.142857z m337.714285-609.714285L477.142857 927.428571q-2.285714 7.428571-8.857143 11.142858T454.857143 940l-35.428572-9.714286q-7.428571-2.285714-11.142857-8.857143T406.857143 907.428571l213.142857-737.714285q2.285714-7.428571 8.857143-11.142857t13.428571-1.428572l35.428572 9.714286q7.428571 2.285714 11.142857 8.857143t1.428571 14z m375.428572 372l-266.285715 266.285714q-5.714286 5.714286-13.142857 5.714286t-13.142857-5.714286l-28.571428-28.571429q-5.714286-5.714286-5.714286-13.142857t5.714286-13.142857l224.571428-224.571428-224.571428-224.571429q-5.714286-5.714286-5.714286-13.142857t5.714286-13.142857l28.571428-28.571429q5.714286-5.714286 13.142857-5.714286t13.142857 5.714286l266.285715 266.285714q5.714286 5.714286 5.714285 13.142858t-5.714285 13.142857z"
      p-id="2559"
    />
  </svg>
);
