import React from "react";

export default ({className = "icon"}) => (
  <svg
    t="1599629184616"
    className="icon"
    viewBox="0 0 1024 1024"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    p-id="9983"
    className={className}
  >
    <path
      d="M872.369231 128c-177.230769 0-313.107692 137.846154-313.107693 315.076923V866.461538c0 15.753846 13.784615 29.538462 29.538462 29.538462h334.769231c15.753846 0 29.538462-13.784615 29.538461-29.538462V531.692308c0-15.753846-13.784615-29.538462-29.538461-29.538462H677.415385v-59.076923c0-98.461538 96.492308-196.923077 194.953846-196.923077h51.2c15.753846 0 29.538462-13.784615 29.538461-29.538461V157.538462c0-15.753846-13.784615-29.538462-29.538461-29.538462h-51.2z m-488.369231 0c-177.230769 0-313.107692 137.846154-313.107692 315.076923V866.461538c0 15.753846 13.784615 29.538462 29.538461 29.538462h334.769231c15.753846 0 29.538462-13.784615 29.538462-29.538462V531.692308c0-15.753846-13.784615-29.538462-29.538462-29.538462H189.046154v-59.076923c0-98.461538 96.492308-196.923077 194.953846-196.923077h51.2c15.753846 0 29.538462-13.784615 29.538462-29.538461V157.538462c0-15.753846-13.784615-29.538462-29.538462-29.538462h-51.2z"
      p-id="9984"
    />
  </svg>
);
