import React from "react";

export default ({className = "icon"}) => (
  <svg
    t="1599629129477"
    className="icon"
    viewBox="0 0 1024 1024"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    p-id="9665"
    className={className}
  >
    <path
      d="M597.333333 768c6.954667 0 13.856-0.213333 20.704-0.629333-3.328 3.733333-6.773333 7.381333-10.357333 10.965333l-128 128c-99.978667 99.978667-262.069333 99.978667-362.037333 0-99.978667-99.968-99.978667-262.048 0-362.026667l128-128c3.584-3.573333 7.242667-7.029333 10.965333-10.357333 78.464-69.845333 189.589333-83.072 280.437333-39.690667a256.021333 256.021333 0 0 1 70.645334 50.037334 255.68 255.68 0 0 1 50.026666 70.645333A85.013333 85.013333 0 0 1 597.333333 512a85.162667 85.162667 0 0 1-25.045333-3.733333 172.074667 172.074667 0 0 0-56.554667-56.565334c-65.728-40.277333-152.832-31.968-209.749333 24.949334l-38.421333 38.432-89.578667 89.557333c-66.645333 66.645333-66.645333 174.709333 0 241.376 66.656 66.645333 174.72 66.645333 241.365333 0l89.568-89.568A342.186667 342.186667 0 0 0 597.333333 768z m309.024-650.357333c-99.978667-99.968-262.058667-99.968-362.037333 0l-128 128c-3.584 3.584-7.029333 7.242667-10.357333 10.976a341.546667 341.546667 0 0 1 109.109333 10.944l89.568-89.568c66.666667-66.656 174.730667-66.656 241.376 0 66.645333 66.645333 66.645333 174.709333 0 241.365333l-89.578667 89.568-38.421333 38.432c-56.917333 56.896-144.021333 65.205333-209.770667 24.938667a171.52 171.52 0 0 1-56.554666-56.544A84.693333 84.693333 0 0 0 426.666667 512a85.034667 85.034667 0 0 0-60.394667 25.045333 255.776 255.776 0 0 0 50.037333 70.645334 255.594667 255.594667 0 0 0 70.634667 50.037333c90.837333 43.392 201.962667 30.165333 280.437333-39.690667a257.92 257.92 0 0 0 10.976-10.368l128-128c99.978667-99.968 99.978667-262.048 0-362.026666z"
      p-id="9666"
    />
  </svg>
);
