import React from "react";

export default ({className = "icon"}) => (
  <svg
    t="1599629060145"
    className="icon"
    viewBox="0 0 1024 1024"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    p-id="8916"
    className={className}
  >
    <path d="M896 64l0 64-128 0-320 768 128 0 0 64-448 0 0-64 128 0 320-768-128 0 0-64z" p-id="8917" />
  </svg>
);
