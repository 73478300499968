import React from "react";

export default ({className = "icon"}) => (
  <svg
    t="1599629337100"
    className="icon"
    viewBox="0 0 1024 1024"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    p-id="10909"
    className={className}
  >
    <path
      d="M164.93478 1022.626844A124.832378 124.832378 0 0 1 76.428624 986.175789l-38.510789-38.448372a125.206876 125.206876 0 0 1 0-177.137145L770.496648 36.700719a125.331708 125.331708 0 0 1 177.074728 0l38.573205 38.198708a125.394124 125.394124 0 0 1 0 177.137145L253.565769 986.175789a124.832378 124.832378 0 0 1-88.630989 36.451055z m-44.377911-80.704133a62.853103 62.853103 0 0 0 88.818238 0L942.078752 208.033159a62.853103 62.853103 0 0 0 0-88.818238l-38.510789-38.510788a62.977935 62.977935 0 0 0-88.880653 0L81.921248 814.593685a62.853103 62.853103 0 0 0 0 88.818237z"
      p-id="10910"
    />
    <path
      d="M695.010508 172.468414l44.10328-44.165695 163.636523 163.405583-44.109521 44.165695zM227.101304 162.656589v-24.966476a87.382665 87.382665 0 0 0 87.382665-87.382665h24.966476a112.349141 112.349141 0 0 1-112.349141 112.349141z"
      p-id="10911"
    />
    <path
      d="M426.83311 162.656589a112.349141 112.349141 0 0 1-112.349141-112.349141h24.966476a87.382665 87.382665 0 0 0 87.382665 87.382665zM339.450445 250.039254h-24.966476a87.382665 87.382665 0 0 0-87.382665-87.382665v-24.966476a112.349141 112.349141 0 0 1 112.349141 112.349141z"
      p-id="10912"
    />
    <path
      d="M339.450445 250.039254h-24.966476a112.349141 112.349141 0 0 1 112.349141-112.349141v24.966476a87.382665 87.382665 0 0 0-87.382665 87.382665zM96.027307 462.254297v-12.483238a43.691332 43.691332 0 0 0 43.691333-43.691332h12.483237a56.17457 56.17457 0 0 1-56.17457 56.17457z"
      p-id="10913"
    />
    <path
      d="M195.89321 462.254297a56.17457 56.17457 0 0 1-56.17457-56.17457h12.483237a43.691332 43.691332 0 0 0 43.691333 43.691332zM152.201877 505.94563h-12.483237a43.691332 43.691332 0 0 0-43.691333-43.691333v-12.483238a56.17457 56.17457 0 0 1 56.17457 56.174571z"
      p-id="10914"
    />
    <path
      d="M152.201877 505.94563h-12.483237a56.17457 56.17457 0 0 1 56.17457-56.174571v12.483238a43.691332 43.691332 0 0 0-43.691333 43.691333zM670.256248 867.959527v-37.449714a156.040473 156.040473 0 0 0 156.040473-156.040473h37.449713a193.490187 193.490187 0 0 1-193.490186 193.490187z"
      p-id="10915"
    />
    <path
      d="M1019.786907 867.959527a193.490187 193.490187 0 0 1-193.490186-193.490187h37.449713a156.040473 156.040473 0 0 0 156.040473 156.040473zM863.746434 1024h-37.449713a156.040473 156.040473 0 0 0-156.040473-156.040473v-37.449714a193.490187 193.490187 0 0 1 193.490186 193.490187z"
      p-id="10916"
    />
    <path
      d="M863.746434 1024h-37.449713a193.490187 193.490187 0 0 1 193.490186-193.490187v37.449714a156.040473 156.040473 0 0 0-156.040473 156.040473z"
      p-id="10917"
    />
  </svg>
);
