import React from "react";

export default ({className = "icon"}) => (
  <svg
    t="1599628612814"
    className="icon"
    viewBox="0 0 1024 1024"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    p-id="5144"
    className={className}
  >
    <path
      d="M450.857143 319.428571l-97.142857 257.142858q18.857143 0 78 1.142857t91.714285 1.142857q10.857143 0 32.571429-1.142857-49.714286-144.571429-105.142857-258.285715zM36.571429 950.857143l1.142857-45.142857q13.142857-4 32-7.142857t32.571428-6 28.285715-8.285715 25.428571-16.571428 17.714286-28.857143l135.428571-352 160-413.714286h73.142857q4.571429 8 6.285715 12l117.142857 274.285714q18.857143 44.571429 60.571428 147.142858t65.142857 156.857142q8.571429 19.428571 33.142858 82.571429t41.142857 96.285714q11.428571 25.714286 20 32.571429 10.857143 8.571429 50.285714 16.857143t48 11.714285q3.428571 21.714286 3.428571 32.571429 0 2.857143-0.285714 7.714286t-0.285714 7.142857q-36 0-108.571429-4.571429t-109.142857-4.571428q-43.428571 0-122.857143 4t-101.714285 4.571428q0-24.571429 2.285714-44.571428l74.857143-16q0.571429 0 7.142857-1.428572t8.857143-2 8.285714-2.571428 8.571429-3.714286 6.285714-4.571429 5.142857-6.285714 1.428571-8q0-9.142857-17.714285-55.142857t-41.142857-101.428571-24-57.142858l-257.142858-1.142857q-14.857143 33.142857-43.714285 111.714286T254.857143 850.857143q0 12.571429 8 21.428571t24.857143 14 27.714285 7.714286 32.571429 4.857143 23.428571 2.285714q0.571429 10.857143 0.571429 33.142857 0 5.142857-1.142857 15.428572-33.142857 0-99.714286-5.714286T171.428571 938.285714q-4.571429 0-15.142857 2.285715t-12.285714 2.285714q-45.714286 8-107.428571 8z"
      p-id="5145"
    />
  </svg>
);
