import React from "react";

export default ({className = "icon"}) => (
  <svg
    t="1599616153807"
    className="icon"
    viewBox="0 0 1024 1024"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    p-id="1170"
    className={className}
  >
    <path
      d="M832.2 127.9v766H192.7v-766h639.5m0-64H192.7c-35.3 0-64 28.7-64 64v766c0 35.3 28.7 64 64 64h639.5c35.3 0 64-28.7 64-64v-766c0-35.4-28.7-64-64-64z"
      fill="#DD9387"
      p-id="1171"
    />
    <path d="M733.039 824.168v-32h97.9v32z" fill="#8C98A6" p-id="1172" />
    <path
      d="M896.2 958.3h-440v-176c0-35.3 28.7-64 64-64h376c35.3 0 64 28.7 64 64v112c0 35.4-28.7 64-64 64z"
      fill="#C1756C"
      p-id="1173"
    />
    <path
      d="M591.6 771c34.4 0 51.8 14.6 51.8 43.8 0 29.4-17.4 44.2-52.2 44.2h-35.4v54.8h-23.4V771h59.2z m-35.8 68h34c10.4 0 18-2 22.8-5.6 4.8-3.8 7.2-10 7.2-18.6 0-8.6-2.6-14.6-7.4-18.2-4.8-3.8-12.4-5.6-22.6-5.6h-34v48zM715.6 771c23.2 0 40.6 6.4 52.6 19.2 11.4 12 17.2 29.4 17.2 52.2 0 22.6-5.8 40-17.2 52.2-12 12.8-29.4 19.2-52.6 19.2h-52.2V771h52.2z m-28.8 122.8h24.4c17.8 0 30.8-4.2 39-12.4 8-8.4 12-21.4 12-39 0-18-4-31.2-12-39.2-8.2-8.2-21.2-12.2-39-12.2h-24.4v102.8zM904.8 771v20h-74v39.6h69.8v20h-69.8v63.2h-23.4V771h97.4z"
      fill="#FFFFFF"
      p-id="1174"
    />
    <path
      d="M342.9 625.1c-5 0-11.1-1.4-16.6-6.9-7.5-5-12.7-12.7-14.6-22.2-3.6-17 3.3-39.3 18-58 20.3-26 48.7-47.8 84.5-64.9 7.9-3.8 16-7.3 24.5-10.5 10.8-18.2 22.3-38.2 34.5-59.9 6.2-11.1 12.5-24.6 18.2-38.9-10.8-22.6-20.5-48.8-26.5-77.9-6.6-32.2 4.9-55.8 17.8-67.2 10.5-9.2 23.2-12.2 35-8.1 19.2 6.6 25.9 27.9 28.3 40 5.2 27.2-5.3 71.9-19.8 111.5 18.9 35.9 39.4 59.6 45.3 66 1.8 2 3.7 4 5.7 6 40.8-2.2 77.4 1 95.9 9.4 22.1 10.1 38.2 29.5 40 48.4 1.1 11.3-3 21.1-11.3 27.1-15.9 11.4-42 7.7-77.4-11.1-20.7-10.9-41.5-25.5-58.9-41-13.9 1.2-28.6 3-43.7 5.8-16.6 3-38.4 8-61.5 16.1-24.4 40.6-45.5 72.1-63 94.3-21.8 27.7-37.8 40.6-51.8 42-0.9-0.1-1.8 0-2.6 0z m1.1-33.6c0.2 0.1 0.3 0.2 0.4 0.2 4.3-2.8 14-10.7 31.2-33.3 9.5-12.4 20.1-27.7 31.8-45.7-19.8 11.7-38.2 26.4-52.6 44.8-9.6 12.3-13.3 25.3-11.9 31.8 0.4 1.8 0.9 2.1 1.1 2.2z m272.1-125.9c7 4.7 14.2 9.1 21.3 13 24.6 13.4 38.4 15.2 43.6 14.8-1.4-4.9-7.7-14.7-21.2-20.8-7.4-3.5-23-6.2-43.7-7z m67 27.3z m-172.5-93.6c-3.3 6.9-6.5 13.3-9.6 18.9-5.8 10.3-11.4 20.3-16.9 29.9 10.3-2.6 21-4.9 31.9-6.9 7.2-1.3 14.5-2.5 21.7-3.4-7.2-9-16.9-22.1-27.1-38.5z m-3.4-158.6c-0.4 0-1.7 0.4-3.4 1.9-4.8 4.2-11.7 16.7-7.6 36.8 2.8 13.5 6.4 26.3 10.6 38.3 6.8-24.8 10.3-47.6 7.7-61.2-2.3-11.8-6.3-15.4-7.3-15.8 0.1 0 0 0 0 0z"
      fill="#DD9387"
      p-id="1175"
    />
  </svg>
);
