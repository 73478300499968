import React from "react";

export default ({className = "icon"}) => (
  <svg
    t="1599628857374"
    className="icon"
    viewBox="0 0 1024 1024"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    p-id="7414"
    className={className}
  >
    <path
      d="M959.872 128c0.032 0.032 0.096 0.064 0.128 0.128l0 767.776c-0.032 0.032-0.064 0.096-0.128 0.128l-895.776 0c-0.032-0.032-0.096-0.064-0.128-0.128l0-767.776c0.032-0.032 0.064-0.096 0.128-0.128l895.776 0zM960 64l-896 0c-35.2 0-64 28.8-64 64l0 768c0 35.2 28.8 64 64 64l896 0c35.2 0 64-28.8 64-64l0-768c0-35.2-28.8-64-64-64l0 0z"
      p-id="7415"
    />
    <path d="M832 288c0 53.024-42.976 96-96 96s-96-42.976-96-96 42.976-96 96-96 96 42.976 96 96z" p-id="7416" />
    <path d="M896 832l-768 0 0-128 224-384 256 320 64 0 224-192z" p-id="7417" />
  </svg>
);
